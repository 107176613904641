
































import { Component } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import AuthApi from "@/apis/AuthApi";
import axios from "axios";
import StorageUtil from "@/utils/StorageUtil";
import { StorageKey } from "@/consts/StorageKey";

@Component
export default class Login extends BaseView {
  private email = "";
  private password = "";

  private api = new AuthApi();

  private async onClickLogin() {
    this.isConnecting = true;
    try {
      const repo = await this.api.login(this.email, this.password);
      const user = repo.data;

      StorageUtil.setObject(StorageKey.USER, user);
      StorageUtil.setString(StorageKey.API_TOKEN, user.api_token);

      this.$router.replace({ name: user.type });
      axios.defaults.headers.common.Authorization = "Bearer " + user.api_token;
    } catch (e) {
      this.handleError(e);
    }
    this.isConnecting = false;
  }
}
