import Vue from "vue";

export default class AuthApi {
  public login(email: string, password: string) {
    return Vue.axios.post("/login", {
      email,
      password,
    });
  }
}
